
import Recorder from 'recorder-core/recorder.mp3.min'
import 'recorder-core/src/extensions/frequency.histogram.view'
import 'recorder-core/src/extensions/lib.fft.js'
import { defineComponent, onMounted, ref, watch } from 'vue'
import { Dialog, Toast } from 'vant'
import { uploadImage } from '@/api/universal/file'

export default defineComponent({
  props: {
    uploadDirectory: {
      type: String,
      default: 'temp'
    },
    url: {
      type: String,
      default: ''
    }
  },

  setup: (props, { emit }) => {
    const waveDom = ref<HTMLDivElement | null>(null)
    let wave: any = null
    let recorder: any = null
    const audioUrl = ref(props.url)
    const btnText = ref('按住开始录音')
    watch(audioUrl, url => {
      emit('update:url', url)
    })
    watch(() => props.url, url => {
      audioUrl.value = url
    })
    const openRecorder = () => new Promise((resolve) => {
      recorder.open(() => {
        if (!wave) {
          wave = Recorder.FrequencyHistogramView({ elem: waveDom.value })
        }
        resolve(true)
      }, (msg: string, isNotAllow: boolean) => {
        if (isNotAllow) {
          Toast.fail('打开录音失败！')
        }
      })
    })
    const resetRecorder = () => {
      recorder.close()
      if (waveDom.value) {
        waveDom.value.innerHTML = ''
        wave = Recorder.FrequencyHistogramView({ elem: waveDom.value })
      }
      btnText.value = '按住开始录音'
    }
    onMounted(() => {
      recorder = Recorder({
        type: 'mp3',
        bitRate: 16,
        sampleRate: 16000,
        onProcess: (buffers: any, powerLevel: any, bufferDuration: any, bufferSampleRate: any) => {
          if (wave) {
            wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate)
          }
        }
      })
      openRecorder()
    })
    return {
      btnText,
      audioUrl,
      waveDom,
      startRecord: () => {
        if (recorder) {
          openRecorder().then(() => {
            recorder.start()
            btnText.value = '录音中...'
          })
        }
      },
      endRecord: () => {
        recorder.stop((blob: Blob) => {
          uploadImage(props.uploadDirectory, new File([blob], '录音.mp3')).then(r => {
            audioUrl.value = r
          })
          resetRecorder()
        }, (msg: string) => {
          resetRecorder()
          Toast.fail('录音失败:' + msg)
        })
      },
      askDel: () => {
        Dialog.confirm({
          title: '确认',
          message: '确认删除录音吗？'
        }).then(() => {
          audioUrl.value = ''
        }).catch(() => {})
      }
    }
  }
})
